import React from "react";
import "./OfferInfo.scss";

function OfferInfo(props) {
  const { language } = props;
  const content = language === "PL" ? (
    <div className="offerinfo-wrapper__content">
      <p>
        Jeśli jesteś zainteresowany ofertą i dostępnością jachtu, zadzwoń lub skontaktuj się telefonicznie, a z przyjemnością omówimy szczegóły i odpowiemy na wszystkie pytania.
      </p>
      <div className="offerinfo-wrapper__content-price">
        <p> 
          Kwota orientacyjna: <u>5 000 Euro/doba*</u>
        </p>
        <p>
          Catering jest odpłatny oddzielnie po wcześniejszym ustaleniu potrzeb klienta.
        </p>
        <p>
          *W cenę wliczona jest obsługa i paliwo.
        </p>
      </div>
      <p>
        Oferujemy rejsy wycieczkowe z portu <strong>Marina Gdynia</strong> na:
      </p>
      <ul>
        <li><strong>Półwysep Helski</strong> – niezwykle malownicze miejsce dla wielbicieli sportów wodnych, idealne dla relaksu i podziwianiu pięknych widoków.</li>
        <li>Wpłynięcie do portu <strong>Gdańsk</strong> mijając molo w Sopocie aż po monumentalne żurawie Stoczni Gdańskiej i przepiękną starówkę z poziomu jakiego mało kto miał okazję zobaczyć.</li>
        <li>Zacumowanie przy molo w Sopocie i delektowanie się wypoczynkiem z całkowicie innej perspektywy!</li>
      </ul>
    </div>
  ) : (
    <div className="offerinfo-wrapper__content">
      <p>
        If you are interested in the offer and the availability of the yacht, please call or contact us by phone, and we will be happy to discuss the details and answer all your questions. 
      </p>
      <div className="offerinfo-wrapper__content-price">
        <p>
          Indicative price: <u>5 000 Euros per day*</u>
        </p>
        <p>
          Catering is charged separately aŌer determining the client's needs beforehand.
        </p>
        <p>
          *The price includes service and fuel.
        </p>
      </div>
      <p>
        We offer cruises from the port of <strong>Marina Gdynia</strong> to:
      </p>
      <ul>
        <li>The <strong>Półwysep Helski</strong> – a picturesque spot for water sports enthusiasts, perfect for relaxation and enjoying beautiful views.</li>
        <li>Entering the port of <strong>Gdańsk</strong>  passing the pier in Sopot up to the monumental cranes of the Gdańsk Shipyard and the beautiful old town from a perspective that few have had the chance to see.</li>
        <li>Docking Yacht at the pier in Sopot and indulging in relaxation from a completely different perspective!</li>
      </ul>
    </div>
  );

  return (
    <div className="offerinfo-wrapper">
      {content}
    </div>
  );
}

export default OfferInfo;