import React from "react";
import "./AboutOurAgency.scss";

function AboutOurAgency({ title, subtitle, contentPL, contentEN, icons, featuresPL, featuresEN, technicalsPL, offerInfoPL, technicalsEN, offerInfoEN }) {
    return (
        <div className="about-our-agency">
            <h2 className="about-our-agency__title">
                {title}
                <span>{subtitle}</span>
            </h2>
            <div className="about-our-agency__content">
                {contentPL}
                {featuresPL ? (
                    <React.Fragment>
                        {featuresPL}
                    </React.Fragment>
                ) : null}
                {technicalsPL ? (
                    <React.Fragment>
                        {technicalsPL}
                    </React.Fragment>
                ) : null}
                {offerInfoPL ? (
                    <React.Fragment>
                        {offerInfoPL}
                    </React.Fragment>
                ) : null}
                {contentEN ? (
                    <React.Fragment>
                        <br />
                        {contentEN}
                    </React.Fragment>
                ) : null}
                {featuresEN ? (
                    <React.Fragment>
                        {featuresEN}
                    </React.Fragment>
                ) : null}
                {technicalsEN ? (
                    <React.Fragment>
                        {technicalsEN}
                    </React.Fragment>
                ) : null}
                {offerInfoEN ? (
                    <React.Fragment>
                        {offerInfoEN}
                    </React.Fragment>
                ) : null}
                {icons && icons.length > 0 && (
                    <div className="about-our-agency__icons">
                        {icons.map((icon, index) => (
                            <img src={icon} alt="" key={index} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default AboutOurAgency;