import React from "react";
import "./Footer.scss";

class Footer extends React.Component {
  render() {
    const { content } = this.props

    return(
      <footer id="contact" className="footer">
        {content.footerData.map((item, index) => (
            <div className="footer__column" key={index}>
              <h3>{item.title}</h3>
              <span>
                {item.address.split('\n').map((line, subIndex) => (
                    <p key={subIndex}>
                      {line.includes('@') ? (
                          <a href={`mailto:${line}`}>{line}</a>
                      ) : line.startsWith('+') ? (
                          <a href={`tel:${line.replace(/\s/g, '')}`}>{line}</a>
                      ) : (
                          line
                      )}
                    </p>
                ))}
              </span>
            </div>
        ))}

      </footer>
    )
  };
}

export default Footer;