import React from 'react';
import {SplashScreen, Seo} from "../components";

function HomePage() {
    return (
      <div>
        <SplashScreen />
        <Seo
          title="Naturmedicin"
          description="Agencja kreatywna inspiruje, projektuje i kreuje. Odkryj naszą pasję do sztuki i projektowania. Tworzymy wizje, które zachwycają."
          tags="sztuka, sesje zdjęciowe, sesje produktowe, projektowanie, kreatywność, wizje, agencja kreatywna, design"
        />
      </div>
    );
}

export default HomePage;