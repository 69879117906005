import React from "react";
import "./List.scss";

function List(props) {
  const { items, title } = props;

  return (
    <div className="list-wrapper">
      {title && <span className="list-wrapper__title">{title}</span>}
      <ul className="list-wrapper__ul">
        {items.map((item, index) => (
          <li className="list-wrapper__item" key={index} dangerouslySetInnerHTML={{ __html: item }} />
        ))}
      </ul>
    </div>
  );
}

export default List;