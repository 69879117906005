import React from "react";
import "./WhyUs.scss";

function WhyUs({ icon, title, content }) {
    if (!icon || !title || !content) {
        return null;
    }

    return (
        <div className="whyus__item">
            <div className="whyus__icon"><img src={icon} alt=""/></div>
            <h2 className="whyus__title">{title}</h2>
            <p className="whyus__content">{content}</p>
        </div>
    );
}

export default WhyUs;