import React from "react";
import "./About.scss";
import WhyUs from "../WhyUs/WhyUs";
import AboutOurAgency from "../AboutOurAgency/AboutOurAgency";
import AboutImages from "../AboutImages/AboutImages";

function About({ whyUsData, aboutOurAgencyData, aboutImagesData, aboutImagesHdData }) {
    return (
        <div id="about" className="about">
            {aboutOurAgencyData.map((data, index) => (
                <AboutOurAgency
                    key={index}
                    title={data.title}
                    subtitle={data.subtitle}
                    contentPL={data.contentPL}
                    contentEN={data.contentEN}
                    featuresPL = {data.featuresPL}
                    technicalsPL = {data.technicalsPL}
                    offerInfoPL = {data.offerInfoPL}
                    featuresEN = {data.featuresEN}
                    technicalsEN = {data.technicalsEN}
                    offerInfoEN = {data.offerInfoEN}
                    icons={data.icons}
                />
            ))}
            {whyUsData.length > 0 && (
                <div className="whyus">
                    {whyUsData.map((data, index) => (
                        <WhyUs
                            key={index}
                            icon={data.icon}
                            title={data.title}
                            content={data.content}
                        />
                    ))}
                </div>
            )}
            <AboutImages images={aboutImagesData} hdImages={aboutImagesHdData} />
        </div>
    );
}

export default About;