import React, { useEffect } from "react";
import "./SplashScreen.scss";
import headerDefault from '../../images/header_default.png';
import headerNaturMedicin from '../../images/header_naturmedicin.png';
import headerCzarter from '../../images/header_czarter.png';
import logoCzarter from '../../images/logo_czarter.svg';
import logoNaturMedicin from '../../images/logo_naturmedicin.svg';

function Nav() {
    useEffect(() => {
        const bgTriggers = document.querySelectorAll('.splashscreen__trigger');

        bgTriggers.forEach(bgTrigger => {
            bgTrigger.addEventListener('mouseover', function () {
                const dataSrc = this.getAttribute('data-src');
                const targetElement = document.querySelector('.' + dataSrc);
                if (targetElement) {
                    targetElement.classList.add('active-bg');
                }
            });

            bgTrigger.addEventListener('mouseout', function () {
                const targetElement = document.querySelector('.active-bg');
                if (targetElement) {
                    targetElement.classList.remove('active-bg');
                }
            });
        });
    }, []);

    return (
        <div className="splashscreen">
            <img src={headerNaturMedicin} alt="Background" className="splashscreen__bg naturmedicin" />
            <img src={headerCzarter} alt="Background" className="splashscreen__bg czarter" />
            <img
                src={headerDefault}
                id="splashscreen__bg"
                className="splashscreen__bg initial"
                alt="Background"
            />
            <div className="splashscreen__center">
                <a className="splashscreen__trigger" data-src="naturmedicin" href="/naturmedicin/">
                    <img src={logoNaturMedicin} className="splashscreen__center--logo naturmedicin-logo" alt="Logo" />
                </a>

                <svg className="splashscreen__center--divider" width="264" height="263" viewBox="0 0 264 263" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M262.5 1L1.5 262" stroke="white" strokeWidth="2" />
                </svg>

                <a className="splashscreen__trigger" data-src="czarter" href="/yacht/">
                    <img src={logoCzarter} className="splashscreen__center--logo czarter-logo" alt="Logo" />
                </a>
            </div>
        </div>
    );
}

export default Nav;
