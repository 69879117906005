import React from "react";
import "./Map.scss";

function Map(props) {
  return (
      <div className="map_container">
        <picture>
          <source media="(min-width:768px)" srcSet={props.mapImg} />
          <img src={props.mapImgMobile} className="map_container--image" alt="Map" />
        </picture>
      </div>
  );
}

export default Map;