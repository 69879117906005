import React from 'react';
import { useRouteError, Link } from 'react-router-dom';
import './error-page.scss';

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    return (
        <div id="error-page" className="error_page__wrapper">
            <div className="error_page">
                <h1 className="error_page--header">Oops!</h1>
                <p>Przepraszamy, wystąpił nieoczekiwany błąd.</p>
                <p className="error_page--back">
                    <Link to="/">Powrót na stronę główną</Link>
                </p>
            </div>
        </div>
    );
}