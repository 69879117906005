import React, { useState } from "react";
import "./AboutImages.scss";

function AboutImages({ images, hdImages }) {
    const [fullscreenImageIndex, setFullscreenImageIndex] = useState(null);

    const openFullscreen = (index) => {
        setFullscreenImageIndex(index);
    };

    const closeFullscreen = () => {
        setFullscreenImageIndex(null);
    };

    const nextImage = () => {
        if (fullscreenImageIndex !== null && fullscreenImageIndex < images.length - 1) {
            setFullscreenImageIndex(fullscreenImageIndex + 1);
        }
    };

    const prevImage = () => {
        if (fullscreenImageIndex !== null && fullscreenImageIndex > 0) {
            setFullscreenImageIndex(fullscreenImageIndex - 1);
        }
    };

    const renderImages = () => {
        const rows = [];
        let rowIndex = 0;
        let currentRowImages = [];

        images.forEach((image, index) => {
            currentRowImages.push(
                <span key={index} onClick={() => openFullscreen(index)}>
                    <img src={image} alt="" />
                </span>
            );

            if (
                currentRowImages.length === 4 ||
                (rowIndex === 1 && currentRowImages.length === 2) ||
                (rowIndex === 2 && currentRowImages.length === 1) ||
                (rowIndex === 3 && currentRowImages.length === 2)
            ) {
                rows.push(
                    <div className="about-images__row" key={rowIndex}>
                        {currentRowImages}
                    </div>
                );
                currentRowImages = [];
                rowIndex++;
            }
        });

        return rows;
    };

    const renderHdImage = (index) => {
        if (hdImages && hdImages[index]) {
            return (
                <img
                    key={index}
                    src={hdImages[index]}
                    alt=""
                />
            );
        }
        return null;
    };

    return (
        <div className="about-images">
            {renderImages()}

            {fullscreenImageIndex !== null && (
                <div className="fullscreen-overlay" onClick={closeFullscreen}>
                    <span className="close-button" onClick={closeFullscreen}>
                        &times;
                    </span>
                    {renderHdImage(fullscreenImageIndex)}
                    {fullscreenImageIndex > 0 && (
                        <button className="prev-button" onClick={(e) => { e.stopPropagation(); prevImage(); }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                                <path d="M39 12L18 32L39 52" stroke="#E4E4E4"/>
                            </svg>
                        </button>
                    )}
                    {fullscreenImageIndex < images.length - 1 && (
                        <button className="next-button" onClick={(e) => { e.stopPropagation(); nextImage(); }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                                <path d="M35 12L14 32L35 52" stroke="white"/>
                            </svg>
                        </button>
                    )}
                </div>
            )}
        </div>
    );
}

export default AboutImages;
