import React from 'react';
import {About, Footer, Header, Map, Nav, Seo} from "../components";
import navLogo from '../images/logo-naturmedicin.svg'
import headerLogo from '../images/logo_naturmedicin.svg';
import mapImg from '../images/map_naturmedicin.png';
import mapImgMobile from '../images/map_naturmedicin_mobile.png';
import headerImageDesktop from '../images/header_naturmedicin.png';
import headerImageMobile from '../images/header_naturmedicin_mobile.png';
import icon1 from '../images/icons/adds.svg';
import icon2 from '../images/icons/post.svg';
import icon3 from '../images/icons/digital.svg';
import icon4 from '../images/icons/project.svg';
const importAll = (r) => {
    return r.keys().map(r);
};

const aboutImages = importAll(
    require.context('../images/aboutimages-natur', false, /\.(png|jpe?g|svg)$/)
);

const aboutImagesHd = importAll(
    require.context('../images/aboutimages-natur/hd', false, /\.(png|jpe?g|svg)$/)
);


function NaturMedicin() {
    return (
        <div className="naturmedicin">
            <Seo
                title="Naturmedicin. Kreatywna agencja reklamowa"
                description="Agencja kreatywna inspiruje, projektuje i kreuje. Odkryj naszą pasję do sztuki i projektowania. Tworzymy wizje, które zachwycają."
                tags="sztuka, sesje zdjęciowe, sesje produktowe, projektowanie, kreatywność, wizje, agencja kreatywna, design"
            />
            <Nav navLogo={navLogo}
                 contactText="Kontakt"
                 agencyText="O agencji"
            />
            <Header
                headerLogo={headerLogo}
                desktopImage={headerImageDesktop}
                mobileImage={headerImageMobile}
                headerIcons={[]}
                useSlider={false}
            />
            <About
                aboutOurAgencyData={[
                    {
                        title: 'O naszej agencji',
                        subtitle: 'naturmedicin',
                        contentPL: 'Jesteśmy Agencją, która zajmuje się profesjonalnym tworzeniem różnorodnych treści audiowizualnych, od koncepcji i planowania po produkcję i postprodukcję, mając na celu dostarczenie atrakcyjnych, angażujących i wartościowych materiałów dla różnych mediów i platform',
                        contentEN: '',
                        icons: []
                    }
                ]}
                whyUsData={[
                    {
                        icon: icon1,
                        title: 'Produkcja reklam',
                        content: 'Tworzenie reklam telewizyjnych, internetowych, radiowych oraz innych form reklamowych, które promują produkty, usługi lub marki.',
                    },
                    {
                        icon: icon2,
                        title: 'Postprodukcja',
                        content: 'Edycja materiałów filmowych, dodawanie efektów specjalnych, korekcja kolorów, montaż dźwięku i obrazu, tworzenie animacji oraz inne prace wykończeniowe ',
                    },
                    {
                        icon: icon3,
                        title: 'Tworzenie treści digital',
                        content: 'Tworzenie treści wideo, animacji i innych materiałów multimedialnych do publikacji na platformach internetowych, w tym na stronach internetowych, kanałach YouTube, platformach streamingowych itp',
                    },
                    {
                        icon: icon4,
                        title: 'Koordynacja projektu',
                        content: 'Zarządzanie procesem produkcji od początku do końca, zapewnianie zgodności z budżetem i harmonogramem, nadzorowanie zespołu kreatywnego i technicznego',
                    }
                ]}
                aboutImagesData={aboutImages}
                aboutImagesHdData={aboutImagesHd}
            />
            <Footer content={{
                footerData: [
                    {
                        title: 'Adres',
                        address: 'Gdynia, PL — GDY\nCentrum\n',
                    },
                    {
                        title: 'Kontakt',
                        address: 'Marcin Sowa\nCreative Director\n+48 664 333 006\nkontakt@naturmedicin.com',
                    }
                ]
            }}
            />
            <Map mapImg={mapImg} mapImgMobile={mapImgMobile} />
        </div>
    );
}

export default NaturMedicin;