import React, { useEffect, useState } from "react";
import "./Header.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Header(props) {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const sliderSettings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        centerMode: true,
        centerPadding: '6%',
    };

    return (
        <div id="top" className="home__header">
            <picture>
                <source media="(min-width:768px)" srcSet={props.desktopImage} />
                <img
                    src={props.mobileImage}
                    className="home__header--image"
                    alt="Header"
                />
            </picture>
            {props.headerLogo && (
                <img
                    src={props.headerLogo}
                    className="home__header--logo"
                    alt="Logo"
                />
            )}
            {props.useSlider && isMobile ? (
                <div className="slider">
                    <Slider {...sliderSettings}>
                        {props.headerIcons.map((icon, index) => (
                            <div
                                className={`slider-item ${index === 0 ? 'first-item' : ''} ${
                                    index === props.headerIcons.length - 1 ? 'last-item' : ''
                                }`}
                                key={index}
                            >
                                <img src={icon} alt={`Icon ${index}`} />
                            </div>
                        ))}
                    </Slider>
                </div>
            ) : (
                <div className="header-icons">
                    {props.headerIcons.map((icon, index) => (
                        <div className="header-icons__icon" key={index}>
                            <img src={icon} alt={`Icon ${index}`} />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default Header;
