import React, { useEffect, useState } from "react";
import "./Nav.scss";
import { Link } from "react-scroll";

function Nav(props) {
    const [isMouseOverPage, setIsMouseOverPage] = useState(false);

    useEffect(() => {
        const hamburger = document.querySelector('#menu_hamburger');
        const close_menu = document.querySelector('#menu_close');
        const menu = document.querySelector('#menu');
        const menuItems = document.querySelectorAll('#menu .cursor-pointer');

        const openMenu = () => {
            menu.classList.add('active');
        };

        const closeMenu = () => {
            menu.classList.remove('active');
        };

        hamburger.addEventListener('click', openMenu);
        close_menu.addEventListener('click', closeMenu);

        menuItems.forEach(item => {
            item.addEventListener('click', closeMenu);
        });

        const handleMouseEnterPage = () => {
            setIsMouseOverPage(true);
        };

        const handleMouseLeavePage = () => {
            setIsMouseOverPage(false);
        };

        document.addEventListener("mouseover", handleMouseEnterPage);
        document.addEventListener("mouseout", handleMouseLeavePage);

        return () => {
            hamburger.removeEventListener('click', openMenu);
            close_menu.removeEventListener('click', closeMenu);
            document.removeEventListener("mouseover", handleMouseEnterPage);
            document.removeEventListener("mouseout", handleMouseLeavePage);
        };
    }, []);

    return (
        <header className={`mainnav ${isMouseOverPage ? "active" : ""}`}>
            <div className="mainnav__wrapper">
                <div className="mainnav__wrapper--logo">
                    <Link
                        to="top"
                        smooth={true}
                        duration={300}
                        className="nav-logo cursor-pointer"
                    >
                        <img src={props.navLogo} alt="Logo" />
                    </Link>
                </div>
                <div id="menu_hamburger" className="mainnav__wrapper--hamburger">
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 12H40" stroke="white" strokeWidth="2"/>
                        <path d="M8 24H40" stroke="white" strokeWidth="2"/>
                        <path d="M8 36H40" stroke="white" strokeWidth="2"/>
                    </svg>
                </div>
                <nav id="menu" className="mainnav__wrapper--nav">
                    <span id="menu_close" className="mainnav__wrapper--close">
                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                          <path d="M12.6863 13L35.3137 35.6274" stroke="white" strokeWidth="2"/>
                          <path d="M23 24H25" stroke="white" strokeWidth="2"/>
                          <path d="M12.6863 35.6274L35.3137 13" stroke="white" strokeWidth="2"/>
                        </svg>
                    </span>
                    <div>
                        <ul className="mainnav__wrapper--links">
                            <li className="menu-item">
                                <Link
                                    to="about"
                                    smooth={true}
                                    duration={300}
                                    className="cursor-pointer"
                                >
                                    <span>{props.agencyText}</span>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link
                                    to="contact"
                                    smooth={true}
                                    duration={300}
                                    className="cursor-pointer"
                                >
                                    <span>{props.contactText}</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    );
}

export default Nav;
