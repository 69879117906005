import React from 'react';
import { Nav, Header, About, Footer, Map, Seo, List, OfferInfo} from "../components";
import logo from '../images/logo_czarter.svg';
import navLogo from '../images/logo-yacht.svg';
import mapImg from '../images/map_czarter.png';
import mapImgMobile from '../images/map_czarter_mobile.png';
import headerImageDesktop from '../images/header_czarter.png';
import headerImageMobile from '../images/header_czarter_mobile.png';

const importAll = (r) => {
    return r.keys().map(r);
};

const aboutImages = importAll(
    require.context('../images/aboutimages-yacht', false, /\.(png|jpe?g|svg)$/)
);

const flagsIcon = importAll(
    require.context('../images/icons/flags', false, /\.(png|jpe?g|svg)$/)
);

const headerIcons = importAll(
    require.context('../images/header-icons', false, /\.(png|jpe?g|svg)$/)
);

const aboutImagesHd = importAll(
    require.context('../images/aboutimages-yacht/hd', false, /\.(png|jpe?g|svg)$/)
);

function Yacht() {
  const featuresPL = [
    "<strong>Organizujemy rejsy dla spotkań biznesowych, w których bezpiecznie i w całkowitej prywatności przeprowadzisz swoje cenne rozmowy</strong>",
    "<strong>Wynająć jacht możesz również na ekskluzywną, luksusową sesję zdjęciową, w wyjątkowy sposób pokazać materiały i zachwycić swoich klientów</strong>",
    "<strong>Jacht pomieści 12 osób – w tym 2 członków załogi</strong>",
    "<strong>Na pokładzie znajdują się trzy sypialnie dla 8 osób</strong> – największa z nich posiada dwuosobowe łoże małżeńskie z prywatną łazienką oraz skórzany szezlong z widokiem na zachwycające widoki przez bulaj i szyby boczne, drugie, mniejsze posiada łoże małżeńskie oraz dzieloną łazienkę a ostatnia posiada dwie koje",
    "<strong>Cobrey 50 Fly</strong> – posiada stanowisko umiejscowione na górnym pokładzie, z którego można delektować się sterując jachtem i spędzić czas z najbliższymi",
    "<strong>Dostępna jest również kuchnia z ekspresem do kawy, zmywarka oraz kostkarka do lodu</strong>"
  ];

  const featuresEN = [
    "<strong>We organize cruises for business meetings, where you can conduct your valuable conversations safely and in total privacy.</strong>",
    "<strong>You can also rent a yacht for an exclusive, luxurious photo shoot to showcase materials in a unique way and impress your clients.</strong>",
    "<strong>The yacht can accommodate 12 people – including 2 crew members.</strong>",
    "<strong>Onboard there are three bedrooms for 8 people</strong> – the largest of which has a double bed with a private bathroom and a leather chaise longue with views of the breathtaking scenery through the bull's eye and side windows; the second, smaller one has a double bed and a shared bathroom; the last one has two bunks.",
    "<strong>Cobrey 50 Fly</strong> – features a helm positioned on the upper deck, from which you can enjoy steering the yacht and spending time with your loved ones.",
    "<strong>There is also a kitchen equipped with a coffee machine, dishwasher, and an ice maker.</strong>"
  ];

  const technicalsPL = [
    "<strong>Zasięg:</strong> około 2 800 km, Zasięg przy prędkości ślizgowej: 400 km",
    "<strong>Maksymalna prędkość:</strong> 35 węzłów / 65km/h",
    "<strong>Długość całkowita:</strong> 16m"
  ]

  const technicalsEN = [
    "<strong>Range:</strong> about 2,800 km, Range at planing speed: 400 km",
    "<strong>Maximum speed:</strong> 35 knots / 65km/h",
    "<strong>Total length:</strong> 16m "
  ]

  return (
    <div className="yacht">
      <Seo
        title="Odkryj luksusowy czarter jachtu. W Gdyni!"
        description="Luksusowy czarter jachtu - marzenie o przygodzie na morzu. Rezerwacje dostępne."
        tags="czarter jachtu, luksusowy czarter, morze, przygoda, rezerwacje"
      />
      <Nav navLogo={navLogo}
        contactText="Contact Us"
        agencyText="About Yacht"
      />
      <Header
        headerLogo={logo}
        desktopImage={headerImageDesktop}
        mobileImage={headerImageMobile}
        headerIcons={headerIcons}
        useSlider={true}
      />
      <About whyUsData={[]}
        aboutOurAgencyData={[
          {
            title: "COBREY 50 FLY",
            subtitle: "Welcome on board!",
            contentPL: "Nasz jacht to nie tylko piękno i elegancja, ale też komfort i funkcjonalność. Jego przestronne wnętrze idealnie nadaje się na prywatne spotkania biznesowe w niepowtarzalnym otoczeniu. Dlaczego nie połączyć przyjemności z pożytecznym i przenieść ważne negocjacje na pokład naszego statku, gdzie w luksusowych warunkach można prowadzić rozmowy w otoczeniu naturalnych piękności Trójmiasta?\nJeśli jesteś fotografem lub planujesz sesję zdjęciową, nasz jacht stanowi doskonałe tło dla twoich kreacji. Oświetlenie odbijające się od wody, eleganckie wnętrza i panoramiczne widoki na miasto sprawią, że twoje zdjęcia będą prawdziwym dziełem sztuki.\nNie czekaj dłużej. Odkryj wyjątkowy świat luksusu, komfortu i niezapomnianych wrażeń na pokładzie naszego ekskluzywnego jachtu w Trójmieście. To doświadczenie, które pozostanie z Tobą na zawsze.",
            contentEN: "Our yacht is not only beauty and elegance but also comfort and functionality. Its spacious interior is perfect for private business meetings in a unique setting. Why not combine pleasure with purpose and move important negotiations aboard our vessel, where you can converse in luxurious conditions surrounded by the natural beauty of the Gdynia?\nIf you are a photographer or planning a photo session, our yacht serves as an excellent backdrop for your creations. The lighting reflected off the water, elegant interiors, and panoramic views of the city will ensure that your photos become a true work of art.\nDon't wait any longer. Discover the exceptional world of luxury, comfort, and unforgettable experiences aboard our exclusive yacht in the Gdynia. It's an experience that will stay with you forever.",
            featuresPL: <List items={featuresPL} />,
            featuresEN: <List items={featuresEN} />,
            technicalsPL: <List items={technicalsPL} title="Dane techniczne"/>,
            offerInfoPL: <OfferInfo language="PL" />,
            technicalsEN: <List items={technicalsEN} title="Technical data"/>,
            offerInfoEN: <OfferInfo language="EN"/>,
            icons: flagsIcon
          }
        ]}
         aboutImagesData={aboutImages}
         aboutImagesHdData={aboutImagesHd}
      />
      <Footer content={{
        footerData: [
          {
            title: 'Home port',
            address: 'GDYNIA, PL - GDY\nNabrzeże Norwerskie',
          },
          {
            title: 'Contact',
            address: '+ 48 899 090 788\nyacht@naturmedicin.com',
          }
        ]
      }}
      />
      <Map mapImg={mapImg} mapImgMobile={mapImgMobile} />
    </div>
  );
}

export default Yacht;